<template>
  <TTView>
    <VRow>
      <VCol>
        <h1>Colors</h1>
      </VCol>
    </VRow>

    <TTExample
      class="mt-3"
      file="themes/examples/Colors.light"
    />

    <TTExample
      class="mt-3"
      file="themes/examples/Colors.dark"
    />

    <div class="mt-12" />
  </TTView>
</template>

<script>
export default {
  name: 'Colors',
};
</script>
